<template>
  <div class="layouts entry empty">
    <div class="empty">
      <suspense>
        <template #default>
          <LazyNuxtPage />
        </template>
        <template #fallback>
          <Loader class="async loader" size="large" />
        </template>
      </suspense>
    </div>
  </div>
</template>

<script setup lang="ts">
useHead({
  titleTemplate: 'GoodTalent | %s',
  title: 'Marketplace for Engineering Talent',
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/_breakpoints.scss' as breakpoint;
@use '@/assets/scss/_colors.scss' as color;

.async.loader {
  height: 100vh;
  width: 100%;
}

.layouts.entry.empty {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  align-content: flex-start;
  background-color: color.$white-1;

  > .empty {
    width: 100%;
  }
}
</style>
